<template>
    <div class="my-0 py-0">
        <v-card :loading="loading" height="100vh">
            <v-card-title>
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    {{ process }}
                    <span class="font-weight-black"
                        >{{ bom.code }} | {{ bom.project.reference }}
                        {{ bom.project.name }}</span
                    >
                </h2>
                <v-btn
                    v-if="bom.rejectionHistory"
                    small
                    icon
                    @click="openRejected"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="bom.reasonsForClosing"
                    small
                    icon
                    @click="openClosingDialog"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-remove-outline
                    </v-icon>
                </v-btn>
                <v-spacer />
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text>
                <v-container fluid ref="container" class="px-0 mt-1 pb-0 mb-0">
                    <v-row no-gutters ref="details">
                        <v-col cols="4" class="pl-0 pt-2">
                            <div>
                                <p class="text-capitalize">
                                    <strong>STATUS: </strong>
                                    {{ ` ${bom.status}` }}
                                </p>
                                <p class="text-capitalize">
                                    <strong>CREATED BY: </strong>
                                    {{
                                        ` ${
                                            bom.creator && bom.creator.name
                                                ? bom.creator.name
                                                : bom.createdBy
                                        }`
                                    }}
                                </p>
                                <p class="text-capitalize">
                                    <strong>PROJECT MANAGER: </strong>
                                    {{
                                        bom.projectManager &&
                                        bom.projectManager.name
                                            ? bom.projectManager.name
                                            : bom.projectManager
                                    }}
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="4" class="pl-0 pt-2">
                            <div>
                                <p>
                                    <strong>CREATED: </strong
                                    >{{
                                        ` ${formatDate(
                                            bom.createdOn.seconds ||
                                                bom.createdOn._seconds
                                        )}`
                                    }}
                                </p>
                                <v-textarea
                                    v-model="bom.notes"
                                    prepend-icon="mdi-text"
                                    prefix="Notes: "
                                    hide-details
                                    rows="1"
                                    class="pt-0"
                                    @blur="saveNotes"
                                    :disabled="readBOMOnly"
                                >
                                </v-textarea>
                            </div>
                        </v-col>
                        <v-spacer />
                        <v-col cols="4" class="d-flex justify-end mb-3">
                            <canvas id="BOMqr"></canvas>
                        </v-col>
                    </v-row>
                </v-container>
                <BOMItems
                    :bom="bom"
                    :itemsHeight="itemsHeight"
                    :readBOMOnly="readBOMOnly"
                    :partialDelivery="partialDelivery"
                    :requestData="requestData"
                    @closeProcessBOM="close"
                    @updateBOM="updateBOM"
                />
            </v-card-text>
            <v-card-actions
                class="mx-3 mt-0 pt-0"
                v-if="bom.status == 'rejected' && enableOptions"
            >
                <v-btn
                    rounded
                    color="red"
                    class="white--text"
                    @click="deleteWarning = true"
                >
                    DELETE
                </v-btn>
                <v-spacer />
            </v-card-actions>
            <v-card-actions
                class="mx-3 mt-0 pt-0"
                v-if="bom.status == 'inProgress' && enableOptions"
            >
                <v-btn
                    rounded
                    color="red"
                    class="white--text"
                    @click="openRejectBom"
                >
                    REJECT
                </v-btn>
                <v-spacer />
                <v-btn
                    rounded
                    color="primary"
                    @click="approve"
                    :loading="loading"
                >
                    APPROVE
                </v-btn>
            </v-card-actions>
            <v-card-actions
                class="mx-3 mt-0 pt-0"
                v-if="
                    bom.status == 'approved' &&
                        enableOptions &&
                        (!partialDelivery || bomsDelivered)
                "
            >
                <div class="d-flex justify-center">
                    <v-btn
                        rounded
                        :disabled="
                            !bom.items.find(
                                item =>
                                    item.deliveries &&
                                    item.deliveries.length > 0
                            )
                        "
                        v-if="!partialDelivery || bomsDelivered"
                        color="#FF9800"
                        class="white--text mr-2"
                        @click="closeWarning = true"
                    >
                        CLOSE
                    </v-btn>
                    <v-btn
                        rounded
                        color="red"
                        class="white--text"
                        @click="openRejectBom"
                        :disabled="
                            bom.deliver == true ||
                                !!bom.items.find(
                                    item =>
                                        item.deliveries &&
                                        item.deliveries.length > 0
                                )
                        "
                        v-if="!partialDelivery"
                    >
                        REJECT
                    </v-btn>
                </div>
                <v-spacer />
                <v-btn
                    v-if="
                        validateQuantity &&
                            sendBOMPermission &&
                            !partialDelivery
                    "
                    rounded
                    color="primary"
                    class="white--text"
                    @click="openPartials"
                >
                    SEND TO DELIVER
                </v-btn>
            </v-card-actions>
            <div
                v-if="$router.currentRoute.name == 'deliver'"
                style="height: 47px !important;"
            ></div>
        </v-card>
        <!--Error Alert-->
        <Errors />
        <!--SEND FOR DELIVERY-->
        <v-dialog :retain-focus="false" persistent v-model="partialsForm">
            <SendPartials
                v-if="partialsForm"
                :bom="bom"
                @partialsAlert="partialsAlert"
                @replaceItems="replaceItems"
                @close="closePartials"
            />
        </v-dialog>
        <!--ALERT MESSAGE-->
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--Close BOM-->
        <v-dialog
            v-model="closeWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="closeWarning">
                <v-card-title class="text-h5">Close BOM</v-card-title>
                <v-card-text>
                    Are you sure you want to close this BOM?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="error" @click="closeWarning = false">
                        CANCEL
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeBOM" color="primary" :loading="loading">
                        CLOSE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--DELETE BOM-->
        <v-dialog
            v-model="deleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="deleteWarning">
                <v-card-title class="text-h5">Delete BOM</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this BOM?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="error" @click="deleteWarning = false">
                        CANCEL
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteBOM"
                        color="primary"
                        :loading="loading"
                    >
                        DELETE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Reject BOM-->
        <v-dialog
            v-model="rejectBomForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="rejectBomForm">
                <v-card-title class="text-h5">Reject</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject this BOM
                        </v-col>
                    </v-row>
                    <v-form v-model="validRejectForm">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRejectBom">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectBOM"
                        text
                        color="error"
                        :loading="loading"
                        :disabled="!rejectedNotes"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectionTable
                v-if="rejectionTable"
                :items="bom.rejectionHistory"
                @closeDialog="closeRejected"
            />
        </v-dialog>
        <!--Closing-->
        <v-dialog persistent max-width="800px" v-model="closingDialog">
            <ClosingTable
                v-if="closingDialog"
                :items="bom.reasonsForClosing"
                @closeDialog="closeClosingDialog"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import qrious from 'qrious'
import API from '@/services/api'

export default {
    name: 'ProcessBOM',
    props: {
        bom: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        process: {
            type: String,
            required: true,
            default: '',
        },
        readBOMOnly: {
            type: Boolean,
            default: () => false,
        },
        partialDelivery: {
            type: Boolean,
            default: () => false,
        },
        requestData: {
            type: Object,
            default: () => {},
        },
        bomsDelivered: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        BOMItems: () => import('@/components/WorkOrders/BOMItems'),
        Errors: () => import('@/components/Layout/Errors'),
        SendPartials: () => import('@/components/WorkOrders/SendPartials.vue'),
        RejectionTable: () =>
            import('@/components/WorkOrders/RejectionBOMTable.vue'),
        ClosingTable: () =>
            import('@/components/WorkOrders/ClosingBOMTable.vue'),
    },
    data: () => ({
        deleteWarning: false,
        closeWarning: false,
        partialsForm: false,
        loading: false,
        tab: null,
        headers: [],
        items: [],
        height: 0,
        itemsHeight: 0,
        activateAlert: false,
        alertMessage: '',
        sendBOMPermission: false,
        enableOptions: true,
        validRejectForm: false,
        rejectionTable: false,
        rejectBomForm: false,
        rejectedNotes: undefined,
        prevNotes: null,
        closingDialog: false,
    }),
    computed: {
        validateQuantity() {
            let conditions = [
                item => {
                    return item.quantity > 0
                },
            ]
            const response = this.bom.items.filter(item => {
                return conditions.every(condition => {
                    return condition(item)
                })
            })
            return response.length > 0
        },
    },
    async mounted() {
        try {
            this.prevNotes = this.bom.notes
            if (this.readBOMOnly) {
                this.enableOptions = false
            }
            // qr code
            new qrious({
                element: document.getElementById('BOMqr'),
                value: `https://es-metals-project-agenda.web.app/work-orders/main-work-orders?bomId=${this.bom.id}`,
                size: 150,
            })
            // retrieve user
            const {
                data: { user },
            } = await API.getMyInfo()
            if (user.permissions.includes('sendBOM')) {
                this.sendBOMPermission = true
            }
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openClosingDialog() {
            this.closingDialog = true
        },
        closeClosingDialog() {
            this.closingDialog = false
        },
        async rejectBOM() {
            try {
                this.loading = true
                let bom = null
                if (this.bom.status == 'approved') {
                    bom = await API.rejectBomAfterApproval({
                        id: this.bom.id,
                        workOrderId: this.bom.workOrderId,
                        rejectedNotes: this.rejectedNotes,
                    })
                } else {
                    bom = await API.rejectBOM({
                        id: this.bom.id,
                        workOrderId: this.bom.workOrderId,
                        rejectedNotes: this.rejectedNotes,
                        notificationId: 'XlXkRVQfI99GydO5F9YF',
                    })
                }
                this.$emit('rejectBOM', bom)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.rejectedNotes = undefined
            }
        },
        inProgress() {
            console.log('inprogress')
        },
        async deleteBOM() {
            try {
                this.loading = true
                await API.deleteBOM({
                    id: this.bom.id,
                    workOrderId: this.bom.workOrderId,
                })
                this.$emit('removeBOM')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        replaceItems(partials) {
            try {
                this.loading = true
                for (const partial of partials.requests) {
                    const index = this.bom.items.findIndex(
                        item => item.id == partial.id
                    )
                    if (index >= 0) {
                        this.bom.items[index].remaining = partial.remaining
                        this.bom.items[index].requests =
                            (this.bom.items[index].requests
                                ? this.bom.items[index].requests
                                : 0) + partial.quantity
                    }
                }
                this.bom.deliver = true
                this.bom.items = [...this.bom.items]
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closePartials() {
            this.partialsForm = false
        },
        openPartials() {
            this.partialsForm = true
        },
        partialsAlert() {
            this.activateAlert = true
            this.alertMessage =
                'Partial quantities have been recorded correctly.'
        },
        sendToDeliver() {
            this.$emit('sendToDeliver')
        },
        sendBOM() {
            this.$emit('sendBOM')
        },
        close() {
            this.$emit('close', this.bom)
        },
        updateBOM(updatedBOM) {
            this.bom = updatedBOM
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                details: { clientHeight: detailsHeight },
            } = this.$refs
            this.height =
                window.innerHeight - containerHeight - detailsHeight + 50
            this.itemsHeight = window.innerHeight - 345
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        async approve() {
            try {
                this.loading = true
                await API.approveBOM({
                    id: this.bom.id,
                    workOrderId: this.bom.workOrderId,
                })
                this.$emit('approve')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async closeBOM() {
            try {
                this.loading = true
                if (
                    !this.bom.items.find(
                        item => item.deliveries && item.deliveries.length > 0
                    )
                ) {
                    throw {
                        name: 'BussinesLogic',
                        message:
                            'There is not deliveries in the BOM. It has almost one item delivered.',
                    }
                }
                await API.closeBOM({
                    id: this.bom.id,
                    workOrderId: this.bom.workOrderId,
                })
                this.closeWarning = false
                this.$emit('closeBOM')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openRejectBom() {
            this.rejectBomForm = true
        },
        closeRejectBom() {
            this.rejectBomForm = false
        },
        openRejected() {
            this.rejectionTable = true
        },
        closeRejected() {
            this.rejectionTable = false
        },
        async saveNotes() {
            if (this.prevNotes != this.bom.notes) {
                try {
                    await API.updateBomNotes({
                        workOrderId: this.bom.workOrderId,
                        bomId: this.bom.id,
                        notes: this.bom.notes,
                    })
                } catch (error) {
                    this.setErrorItems({
                        source: this.$options.name,
                        message: error.message,
                    })
                }
            }
        },
    },
}
</script>

<style></style>
